
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`These are the recent updates on CoVariants in reverse chronological order. For live updates and other interesting content follow `}<a parentName="p" {...{
        "href": "https://twitter.com/firefoxx66"
      }}>{`@firefoxx66`}</a></p>
    <h2 {...{
      "id": "2024-01-13"
    }}>{`2024-01-13`}</h2>
    <ul>
      <li parentName="ul">{`Add 23G (XBB.1.5.70), 23H (HK.3), 23I (BA.2.86) to graphing, pages, and mutations`}</li>
    </ul>
    <h2 {...{
      "id": "2023-08-15"
    }}>{`2023-08-15`}</h2>
    <ul>
      <li parentName="ul">{`Add 23C (CH.1.1), 23D (XBB.1.9), 23E (XBB.2.3), and 23F (EG.5.1) to graphing; pages & mutations to come soon`}</li>
    </ul>
    <h2 {...{
      "id": "2023-04-26"
    }}>{`2023-04-26`}</h2>
    <ul>
      <li parentName="ul">{`Add 23B (XBB.1.16) page and on plots`}</li>
    </ul>
    <h2 {...{
      "id": "2023-03-02"
    }}>{`2023-03-02`}</h2>
    <ul>
      <li parentName="ul">{`Stop the Nextstrain builds of 21A, 21I, 21J (Delta) due to lack of circulation`}</li>
      <li parentName="ul">{`Stop 'bird' builds and partial combined Omicron builds`}</li>
    </ul>
    <h2 {...{
      "id": "2023-02-10"
    }}>{`2023-02-10`}</h2>
    <ul>
      <li parentName="ul">{`Add 23A (XBB.1.5) page and on plots`}</li>
    </ul>
    <h2 {...{
      "id": "2022-11-22"
    }}>{`2022-11-22`}</h2>
    <ul>
      <li parentName="ul">{`Fix 'recombinants' category to be fully functional in plots & side-bar menus`}</li>
    </ul>
    <h2 {...{
      "id": "2022-11-03"
    }}>{`2022-11-03`}</h2>
    <ul>
      <li parentName="ul">{`Add a 22F (XBB) page and on plots`}</li>
      <li parentName="ul">{`Include 'recombinants' as a group on Per Country & Cases plots (side-bar not functional yet)`}</li>
    </ul>
    <h2 {...{
      "id": "2022-10-21"
    }}>{`2022-10-21`}</h2>
    <ul>
      <li parentName="ul">{`Add a 22E (BQ.1) page and on plots`}</li>
      <li parentName="ul">{`Remove Beta & Gamma from the 'important' (default view) buttons`}</li>
    </ul>
    <h2 {...{
      "id": "2022-09-21"
    }}>{`2022-09-21`}</h2>
    <ul>
      <li parentName="ul">{`Start using the newly refactored script cluster_analysis.py, replacing allClusterDynamics_faster.py - runtime is cut to less than 30 minutes and output is the same. This refactoring was done by Moira Zuber, to which CoV is very grateful!`}</li>
    </ul>
    <h2 {...{
      "id": "2022-08-22"
    }}>{`2022-08-22`}</h2>
    <ul>
      <li parentName="ul">{`Up the threshold for number of sequences for a country to be plotted in Per variant view from 1200 to 2000`}</li>
    </ul>
    <h2 {...{
      "id": "2022-08-12"
    }}>{`2022-08-12`}</h2>
    <ul>
      <li parentName="ul">{`Add proper links to the footer of the clades graph on the front page`}</li>
      <li parentName="ul">{`Add 'on demand' graph loading on Per Cluster, Cases, and Per Variant pages, thanks to Richard Goater!`}</li>
    </ul>
    <h2 {...{
      "id": "2022-07-26"
    }}>{`2022-07-26`}</h2>
    <ul>
      <li parentName="ul">{`Added 22D page and plots. Removed Iota from Shared Mutation page`}</li>
    </ul>
    <h2 {...{
      "id": "2022-07-25"
    }}>{`2022-07-25`}</h2>
    <ul>
      <li parentName="ul">{`Updated Delta shared mutations, defining mutations, & Delta pages to reflect S:G142D as being a defining mutation. WT is likely shown due to a sequencing issue with ARTIC v3 primers. Thanks to Paula Ruiz Rodriguez for spotting this!`}</li>
    </ul>
    <h2 {...{
      "id": "2022-07-22"
    }}>{`2022-07-22`}</h2>
    <ul>
      <li parentName="ul">{`Per Variants page now displays frequencies in 2 week intervals, unsmoothed (previously this was 1-week intervals with smoothing)`}</li>
      <li parentName="ul">{`In both Per Variants & Per Country the 'New Year bug' is fixed. This caused random days to be missed around New Year (due to inability to fit 2-week epi-week intervals neatly into every year). This can in some areas of low sequencing numbers alter how plots display slightly.`}</li>
    </ul>
    <h2 {...{
      "id": "2022-05-04"
    }}>{`2022-05-04`}</h2>
    <ul>
      <li parentName="ul">{`Added the 22A (BA.4), 22B (BA.5), and 22C (BA.2.12.1) variants, in line with updates to Nextstrain's recognised clades`}</li>
    </ul>
    <h2 {...{
      "id": "2022-04-28"
    }}>{`2022-04-28`}</h2>
    <ul>
      <li parentName="ul">{`Added lists of 10 most-frequent non-defining amino-acid mutations to each variant page (spike, and non-spike)`}</li>
    </ul>
    <h2 {...{
      "id": "2022-04-21"
    }}>{`2022-04-21`}</h2>
    <ul>
      <li parentName="ul">{`A new 'Cases' page now shows cases from Our World in Data, coloured by variant`}</li>
    </ul>
    <h2 {...{
      "id": "2022-03-30"
    }}>{`2022-03-30`}</h2>
    <ul>
      <li parentName="ul">{`All non-human samples are now filtered out from CoVariants graphs`}</li>
      <li parentName="ul">{`All samples with a quality control (QC) score of 'bad' or '' from NextClade are filtered out of CoVariants graphs`}</li>
    </ul>
    <h2 {...{
      "id": "2022-03-07"
    }}>{`2022-03-07`}</h2>
    <ul>
      <li parentName="ul">{`Add direct URL linking to user selection of variants & countries (or regions) on Per Country and Per Variant pages`}</li>
    </ul>
    <h2 {...{
      "id": "2022-01-13"
    }}>{`2022-01-13`}</h2>
    <ul>
      <li parentName="ul">{`Correct suspected origin date of 20A/S:126A to late 2020 (to reflect publication cited)`}</li>
      <li parentName="ul">{`Add '(Omicron)' to 21L in shared mutations table`}</li>
    </ul>
    <h2 {...{
      "id": "2022-01-11"
    }}>{`2022-01-11`}</h2>
    <h3 {...{
      "id": "including-21l-omicron-in-plotting"
    }}>{`Including 21L (Omicron) in plotting`}</h3>
    <ul>
      <li parentName="ul">{`Include 21L (Omicron) in all plotting`}</li>
      <li parentName="ul">{`Create separate 21L (Omicron) page, add to name table, etc`}</li>
      <li parentName="ul">{`Separate out "VoC Header" from VoC pages to be an import - easier to update`}</li>
    </ul>
    <h3 {...{
      "id": "bugfix-double-dates-appearing"
    }}>{`Bugfix: Double-dates appearing`}</h3>
    <ul>
      <li parentName="ul">{`Bugfix for year-switch 'week 0' bug in 2-week datasets`}</li>
    </ul>
    <h2 {...{
      "id": "2022-01-05"
    }}>{`2022-01-05`}</h2>
    <ul>
      <li parentName="ul">{`Add plot of all Nextstrain clades to Variants page`}</li>
    </ul>
    <h2 {...{
      "id": "2021-12-21"
    }}>{`2021-12-21`}</h2>
    <ul>
      <li parentName="ul">{`Add 21M (parent of 21L and 21K) to 21K page`}</li>
      <li parentName="ul">{`Updates to the content on 21K page`}</li>
    </ul>
    <h2 {...{
      "id": "2021-12-15"
    }}>{`2021-12-15`}</h2>
    <ul>
      <li parentName="ul">{`Restructuring of the spacing of pages`}</li>
      <li parentName="ul">{`Graphs have more space around them and Y-axis is less squashed`}</li>
      <li parentName="ul">{`Plot tooltip on Per Variant page now sticks to top of page as you scroll`}</li>
      <li parentName="ul">{`Thanks to Richard Goater for these!`}</li>
    </ul>
    <h2 {...{
      "id": "2021-12-13"
    }}>{`2021-12-13`}</h2>
    <ul>
      <li parentName="ul">{`Add 21L section to the 21K page`}</li>
      <li parentName="ul">{`Add 21L to the shared mutations table`}</li>
    </ul>
    <h2 {...{
      "id": "2021-11-19"
    }}>{`2021-11-19`}</h2>
    <ul>
      <li parentName="ul">{`Update the Per Cluster plotting threshold to 500 sequences to reduce number of countries being plotted (we run out of colours)`}</li>
    </ul>
    <h2 {...{
      "id": "2021-11-18"
    }}>{`2021-11-18`}</h2>
    <h3 {...{
      "id": "region-selection-on-per-country-page"
    }}>{`Region Selection on Per Country page`}</h3>
    <ul>
      <li parentName="ul">{`In the 'countries' menu on the left, one can now select a whole region (Europe, Asia) and just countries from selected regions will be displayed`}</li>
    </ul>
    <h2 {...{
      "id": "2021-11-15"
    }}>{`2021-11-15`}</h2>
    <ul>
      <li parentName="ul">{`Added new Nextstrain Delta clades 21I (Delta) & 21J (Delta). Both are part of the WHO Delta VoC`}</li>
      <li parentName="ul">{`Update pages for Delta, Kappa, and references to the four WHO VoC`}</li>
      <li parentName="ul">{`Update colors on the Per Country page`}</li>
    </ul>
    <h2 {...{
      "id": "2021-11-10"
    }}>{`2021-11-10`}</h2>
    <h3 {...{
      "id": "pdf-plots-removed"
    }}>{`PDF plots removed`}</h3>
    <ul>
      <li parentName="ul">{`Less well-done PDF plots of the same information as is on the website are no longer generated, and removed from the github`}</li>
      <li parentName="ul">{`Markdown versions of tables are also no longer generated, and removed from the github`}</li>
      <li parentName="ul">{`Many old files have been deleted from the github in a cleanup`}</li>
    </ul>
    <h2 {...{
      "id": "2021-10-11"
    }}>{`2021-10-11`}</h2>
    <h3 {...{
      "id": "update-of-per-variant-cluster-threshold"
    }}>{`Update of 'Per Variant' cluster threshold`}</h3>
    <ul>
      <li parentName="ul">{`Countries now have to have at least 400 sequences to appear in Per Variant plots`}</li>
    </ul>
    <h3 {...{
      "id": "removed"
    }}>{`Removed`}</h3>
    <ul>
      <li parentName="ul">{`20A/S.126A (B.1.620) builds. There is very little showing up anymore, though a couple in Switzerland end of Aug. (Graphing will continue)`}</li>
      <li parentName="ul">{`20B/S.732A (B.1.1.519) builds. A little bit of this continues in Mexico but it is expected to fade away. (Graphing will continue)`}</li>
    </ul>
    <h2 {...{
      "id": "2021-09-16"
    }}>{`2021-09-16`}</h2>
    <h3 {...{
      "id": "added"
    }}>{`Added`}</h3>
    <ul>
      <li parentName="ul">{`New data in from Brazil, Chile, Peru - all seem to match with continuing expansion of Delta`}</li>
    </ul>
    <h2 {...{
      "id": "2021-09-02"
    }}>{`2021-09-02`}</h2>
    <h3 {...{
      "id": "removed-1"
    }}>{`Removed`}</h3>
    <ul>
      <li parentName="ul">{`P.2 from the mutation comparison`}</li>
      <li parentName="ul">{`Epsilon builds - no samples since end of July (Graphing will continue)`}</li>
      <li parentName="ul">{`20A.EU2 builds - no samples since July (Graphing will continue)`}</li>
      <li parentName="ul">{`20A/S:439K - no samples since June (Graphing will continue)`}</li>
      <li parentName="ul">{`20A/S:98F - no samples since July (Graphing will continue)`}</li>
    </ul>
    <h2 {...{
      "id": "2021-01-28"
    }}>{`2021-01-28`}</h2>
    <h3 {...{
      "id": "initial-release"
    }}>{`Initial release`}</h3>
    <ul>
      <li parentName="ul">{`Interactive graphs for per-country and per-variant`}</li>
      <li parentName="ul">{`'Mutation badges' for AA & nucleotide mutations`}</li>
      <li parentName="ul">{`Variant buttons dynamically generated and ordered`}</li>
      <li parentName="ul">{`New FAQ & Home pages`}</li>
      <li parentName="ul">{`Mutation table with sortable mutation options`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;